import http from "../axios-instance";

class SettingsService {
  getSettings() {
    return http
      .get("/settings")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateSettings(settings) {
    return http
      .post("/settings", settings)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  refreshSettings() {
    return http
      .get("/settings")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getThemeSettings() {
    return http
      .get("/theme_settings")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateThemeSettings(settings, config) {
    return http
      .post("/theme_settings", settings, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getNotificationSettings() {
    return http
      .get("/notification_settings")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateNotificationSettings(settings) {
    return http
      .post("/notification_settings", settings)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  sendNotificationRequest(data) {
    return http
      .post("/notification_request", data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getVat(country) {
    return http
      .get("/vat/" + country)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  purgeAllCaches() {
    return http
      .delete("/caches/purge-all")
      .then((res) => Promise.resolve(res.data))
      .catch((err) => Promise.reject(err));
  }

  getSupportedTools() {
    return http
      .get("/settings/supported-tools")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateSupportedTools(tools) {
    return http
      .put("/settings/supported-tools", {
        tools: tools ?? "",
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new SettingsService();
