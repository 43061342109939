import http from "../axios-instance";

class VariantService {
  create(model) {
    return http
      .post("/vehicle/variant", model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  update(model) {
    return http
      .put(`/vehicle/variant/${model._id}`, model)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  async updateDetails(_id, model) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    
    await http
      .put(`/vehicle/vdetail/${_id}/details`, model, config)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getAll(params) {
    return http
      .get("/vehicle/variant", { params: params })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getEcuBrandsForDropdown() {
    return http
      .get("/vehicle/variant/ecu-brands/for-dropdown")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getOne(_id) {
    return http
      .get(`/vehicle/variant/${_id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  delete(model) {
    return http
      .delete(`/vehicle/variant/${model._id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getSelected(params) {
    return http
      .get(`/vehicle/variant/selected/${params?.generation ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  getCompleted(params) {
    return http
      .get(`/vehicle/variant/completed/${params?.generation ?? ""}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
  
  regSearch(reg_no) {
    return http
      .get(`/vehicle/reg_search/${reg_no}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}

export default new VariantService();
